<template>
<div class="footer-section container-fluid">
    <div class="footer-col row">
        <!-- <img src="/images/landing/vertical-logo-syncgrades.png" alt="feature"> -->

        <router-link
            class="text-center"
            :to="{ name: 'LandingPage' }"
        >
            <SVGIcon
                :name="'syncgrades'"
                style="width: 80px; height: 80px"
            />
            <br>
            <img
                class="pt-3 pb-3"
                alt="SyncGrades"
                src="/logos/logo-text-dark.svg"
                style="width: 128px"
            >
        </router-link>

        <div class="quick-links pb-4">
            <ul>
                <router-link
                    :to="{name: 'ContactUs'}"
                >
                    <li>Contact Us</li>
                </router-link>
                <router-link
                    :to="{name: 'TechSupport'}"
                >
                    <li>Support</li>
                </router-link>
            </ul>
        </div>

        <div class="social-links d-none">
            <a target="_blank" href="https://www.facebook.com/syncgrades1/">
                <i class="socicon-facebook mt-1 kt-font-light" />
            </a>

            <a target="_blank" href="https://www.instagram.com/sync.grades/">
                <i class="socicon-instagram mt-1 kt-font-light" />
            </a>

            <a target="_blank" href="https://twitter.com/syncgrades/">
                <i class="socicon-twitter mt-1 kt-font-light" />
            </a>
        </div>

        <div class="copy-right">
            <p>© {{ year }} SyncGrades. All rights reserved.</p>
            <div>
                <a href="/privacy-policy">Privacy Policy</a>
                <a href="/terms-and-conditions">Terms & Conditions</a>
                <a href="/bill-of-rights">Parents Bill of Rights</a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'FooterSG',
    computed: {
        year() {
            return new Date().getFullYear();
        },
    },
};
</script>

<style scoped lang="scss">
.footer-section {
  background: linear-gradient(180deg, #13263c, #0a1a2e);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 10px 20px 10px;

  .footer-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1200px;

    img {
      min-width: 140px;
      max-width: 200px;
      margin-bottom: 30px;
    }

    .quick-links {
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        li {
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 500;
          margin: 0 20px;
          cursor: pointer;
          color: #ebefff;
        }
      }
    }

    .social-links {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 20px;
      i {
        margin: 0 10px;
        font-size: 1.5rem;
      }
    }

    .copy-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px dashed #68696e;

      p {
        font-size: 1em;
        font-weight: 500;
        color: #ebefff;
        margin-bottom: 20px;
        margin: 0 20px;
      }

      div {
        display: flex;

        a {
          letter-spacing: 1px;
          font-size: 1em;
          font-weight: 500;
          margin: 0 20px;
          cursor: pointer;
          color: #ebefff;
        }
      }
    }
  }
}

/* @media screen and (max-width: 1024px) {
} */
@media screen and (max-width: 768px) {
  .copy-right {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    p {
      margin-bottom: 20px;
    }
  }
}
@media screen and (max-width: 425px) {
  .footer-section {
    .footer-col {
      img {
        width: 60%;
      }
    }
  }
}
</style>
