const appLoginPage = "https://app.syncgrades.com/login";

const landingMixins = {
    computed: {
        appLoginPage() {
            return appLoginPage;
        },
        shouldRedirectUser() {
            const { href } = window.location;
            if (href.includes('localhost')) return false;
            if (href.includes('app.syncgrades.com')) return false;
            return true;
        },
    },
};

export {
    landingMixins as default,
};
