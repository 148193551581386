var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer-section container-fluid" }, [
    _c(
      "div",
      { staticClass: "footer-col row" },
      [
        _c(
          "router-link",
          {
            staticClass: "text-center",
            attrs: { to: { name: "LandingPage" } },
          },
          [
            _c("SVGIcon", {
              staticStyle: { width: "80px", height: "80px" },
              attrs: { name: "syncgrades" },
            }),
            _c("br"),
            _c("img", {
              staticClass: "pt-3 pb-3",
              staticStyle: { width: "128px" },
              attrs: { alt: "SyncGrades", src: "/logos/logo-text-dark.svg" },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "quick-links pb-4" }, [
          _c(
            "ul",
            [
              _c("router-link", { attrs: { to: { name: "ContactUs" } } }, [
                _c("li", [_vm._v("Contact Us")]),
              ]),
              _c("router-link", { attrs: { to: { name: "TechSupport" } } }, [
                _c("li", [_vm._v("Support")]),
              ]),
            ],
            1
          ),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "copy-right" }, [
          _c("p", [
            _vm._v(
              "© " + _vm._s(_vm.year) + " SyncGrades. All rights reserved."
            ),
          ]),
          _vm._m(1),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "social-links d-none" }, [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.facebook.com/syncgrades1/",
          },
        },
        [_c("i", { staticClass: "socicon-facebook mt-1 kt-font-light" })]
      ),
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://www.instagram.com/sync.grades/",
          },
        },
        [_c("i", { staticClass: "socicon-instagram mt-1 kt-font-light" })]
      ),
      _c(
        "a",
        {
          attrs: { target: "_blank", href: "https://twitter.com/syncgrades/" },
        },
        [_c("i", { staticClass: "socicon-twitter mt-1 kt-font-light" })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("a", { attrs: { href: "/privacy-policy" } }, [
        _vm._v("Privacy Policy"),
      ]),
      _c("a", { attrs: { href: "/terms-and-conditions" } }, [
        _vm._v("Terms & Conditions"),
      ]),
      _c("a", { attrs: { href: "/bill-of-rights" } }, [
        _vm._v("Parents Bill of Rights"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }