var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header fixed-top" },
    [
      _c(
        "b-navbar",
        {
          staticClass: "inner-header",
          attrs: { toggleable: "lg", type: "dark" },
        },
        [
          _c(
            "b-navbar-brand",
            { staticClass: "logo-box", attrs: { href: "/" } },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "LandingPage" } } },
                [
                  _c("SVGIcon", {
                    staticClass: "pt-2 pb-2 mr-2",
                    staticStyle: { width: "60px", height: "60px" },
                    attrs: { name: "syncgrades" },
                  }),
                  _c("img", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      alt: "SyncGrades",
                      src: "/logos/logo-text-dark.svg",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto right" },
                [
                  _c(
                    "b-navbar-nav",
                    { staticClass: "menu-items-alignment" },
                    [
                      _c(
                        "b-nav-item",
                        {
                          staticClass: "syncgrades-menu-item",
                          attrs: { href: "/contact" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "sg-item",
                              attrs: { to: { name: "ContactUs" } },
                            },
                            [_vm._v(" Contact ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-nav-item",
                        {
                          staticClass: "syncgrades-menu-item",
                          attrs: { href: "/about" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "sg-item",
                              attrs: { to: { name: "AboutUs" } },
                            },
                            [_vm._v(" About ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-nav-item",
                        {
                          staticClass: "syncgrades-menu-item",
                          attrs: { href: "/support" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "sg-item",
                              attrs: { to: { name: "TechSupport" } },
                            },
                            [_vm._v(" Support ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "icon-box" },
                        [
                          _vm.shouldRedirectUser
                            ? _c(
                                "a",
                                {
                                  staticClass: "sg-item",
                                  attrs: { href: _vm.appLoginPage },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/images/landing/login-icon.png",
                                      alt: "logo",
                                    },
                                  }),
                                ]
                              )
                            : _c(
                                "router-link",
                                {
                                  staticClass: "sg-item",
                                  attrs: { to: { name: "Login" } },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/images/landing/login-icon.png",
                                      alt: "logo",
                                    },
                                  }),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }